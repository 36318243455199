import styled, { css } from "styled-components";
import { useEffect, useContext, useRef } from "react";
import {
  AnalyticsContext,
  useLocationContext,
  useBrandingContext,
  useAuthContext,
} from "Context";
import { useEventList } from "Hooks";
import { LogoWhite } from "Images";
import {
  Footer as FooterBase,
  FooterCopyright,
  FooterLanguageContainer,
  FooterLink,
  Button,
} from "Components";
import {
  FlexColumn,
  FlexRow,
  H1,
  P,
  Footnote,
  Icon,
  Autocomplete as AutocompleteBase,
  Link,
  H4,
  Subheader,
} from "notes";
import { useHistory } from "react-router-dom";
import { useUser } from "auth";

export const Landing = () => {
  const { logClick } = useContext(AnalyticsContext);
  const { claims } = useUser();
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext();
  const { logo, brand, landing } = useBrandingContext();
  const history = useHistory();
  const {
    hasLocationError,
    handleLocationRequest,
    location,
    locationLoading,
    allowLocationSearch,
    setAllowLocationSearch,
  } = useLocationContext();

  const { events, eventsLoading } = useEventList(location);

  const eventsRef = useRef(events);

  useEffect(() => {
    eventsRef.current = events;
  }, [events]);

  const closestEvent = events?.length ? events[0] : null;

  useEffect(() => {
    if (!!location && allowLocationSearch && !!closestEvent) {
      history.push(`/event/${closestEvent._id}`, { showConfirm: true });
    }
  }, [location, closestEvent]);

  useEffect(() => {
    if (isArtist || isAdmin) {
      history.push("/artist");
    }
  }, [isArtist, isAdmin]);

  const onFindShowClickHandler = () => {
    logClick({
      label: "Find a show",
      url: window.location.href,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onArtistClickHandler = (path) => {
    logClick({
      label: path,
      url: window.location.href,
    });
    history.push(path);
  };

  const contentBlocks = landing?.blocks?.map((block) => ({
    ...block,
    onClick:
      block.link === "scroll-top"
        ? onFindShowClickHandler
        : block.link?.startsWith("/")
        ? () => onArtistClickHandler(block.link)
        : () => window.open(block.link, "_blank"),
  }));

  const search = async (searchTerm) =>
    new Promise((resolve, reject) => {
      const handler = () =>
        resolve(
          eventsRef.current?.filter(
            ({ venue, artistName }: any) =>
              venue.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
              artistName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          )
        );
      const retry = () => {
        if (eventsRef.current) {
          handler();
        } else {
          setTimeout(() => {
            retry();
          }, 50);
        }
      };
      setTimeout(retry, 0);
    });

  const onEventSelectClickHandler = (result) => {
    logClick({
      label: `Select Show - ${result.artistName}`,
      url: window.location.href,
    });
    history.push(`/event/${result._id}`, { state: { showConfirm: true } });
  };

  const noEvents = events !== undefined && events?.length === 0;
  const blocks = !noEvents ? contentBlocks : contentBlocks.splice(1);
  const isLocationLoading = locationLoading && allowLocationSearch;
  return (
    <Wrapper flex="1 0 100%">
      <Container>
        <Header yCenter>
          {logo ? <img src={logo} alt={brand} /> : <Logo />}
          <Icon
            indicator
            name="Questions"
            onClick={() => history.push("/contact")}
            style={{ cursor: "pointer" }}
          />
        </Header>
        {!noEvents ? (
          <ShowSearch flex="1 1 100%">
            <H1>{landing?.title}</H1>
            <Autocomplete
              leftIcon={<Icon form name="Search" />}
              onChange={() => {}}
              search={search}
              placeholder="Search by artist name or venue..."
              Result={({ result }, index) => {
                return !!result?.text ? (
                  <Item key={index}>
                    <P>No matches found...</P>
                  </Item>
                ) : (
                  <Item
                    key={index}
                    onClick={() => onEventSelectClickHandler(result)}
                  >
                    <P>{result.artistName}</P>
                    <Footnote>
                      <span>{result.venue}</span> • {result.address}
                    </Footnote>
                  </Item>
                );
              }}
            />
            <FlexColumn style={{ position: "relative" }}>
              <ButtonLocation
                onClick={() => {
                  if (!allowLocationSearch) {
                    setAllowLocationSearch(true);
                  }
                  handleLocationRequest();
                }}
                disabled={isLocationLoading}
              >
                {isLocationLoading ? (
                  <>
                    <LoaderInline />
                    {landing?.buttonLoading}
                  </>
                ) : (
                  <>
                    <Icon form name="CurrentLocation" />
                    {landing?.button}
                  </>
                )}
              </ButtonLocation>
            </FlexColumn>
            {hasLocationError && (
              <LocationErrorMessage>
                <Footnote>
                  We were unable to find your location. Please enable location
                  services or use the search field above.
                </Footnote>
              </LocationErrorMessage>
            )}
          </ShowSearch>
        ) : (
          <ShowSearch>
            <H1>{contentBlocks[0].title}</H1>
            <H4>{contentBlocks[0].body}</H4>
            <Subheader>New Shows Coming Soon!</Subheader>
          </ShowSearch>
        )}
        {blocks?.map(({ title, body, image, button, onClick }, index) => (
          <ImageLockup xEnd={index === 0} key={index}>
            <Image imageSrc={image} />
            <Card>
              <H1>{title}</H1>
              <P>{body}</P>
              <Button onClick={onClick}>{button}</Button>
            </Card>
          </ImageLockup>
        ))}
        <Footer />
      </Container>
    </Wrapper>
  );
};

export const extractStyles = (props: any, key: string) =>
  props.theme.branding.landing?.colors[key];

const Autocomplete = styled(AutocompleteBase)``;

const Footer = styled(FooterBase)`
  ${FooterLanguageContainer} {
    display: none;
  }
  ${FooterLink} {
    color: ${(props) => props.theme.branding.landing.colors.footerLinks};
  }
  ${FooterCopyright} {
    color: ${(props) => props.theme.branding.landing.colors.copyright};
  }
`;

const Wrapper = styled(FlexColumn)`
  background: ${(props) => extractStyles(props, "page")};
`;

const Container = styled(FlexColumn)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Header = styled(FlexRow)`
  color: #ffffff;
  justify-content: space-between;
  padding: 24px;
  img {
    width: 121px;
    margin-bottom: -4px;
    object-fit: contain;
  }
  svg {
    path {
      fill: ${(props) => props.theme.branding.landing.colors.headerActions};
    }
  }
`;

const Logo = styled(LogoWhite)`
  width: 120px;
  height: 24px;
`;

const ShowSearch = styled(FlexColumn)`
  padding: 16px 24px 40px;
  position: relative;
  width: 100%;
  ${H1} {
    color: ${(props) => extractStyles(props, "title")};
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    text-transform: none;
  }
  ${H4} {
    color: #ffffff;
    letter-spacing: 0;
  }
  ${Subheader} {
    font-family: ${(props) => props.theme.branding.headerFont};
    color: #a7dbda;
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
    text-transform: uppercase;
  }
`;

const LocationErrorMessage = styled(FlexRow)`
  margin-top: 12px;
  margin-bottom: -27px;
  ${Footnote} {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 15px;
  }
  ${Link} {
    margin-left: 6px;
    color: #ffffff;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    svg {
      margin-right: 2px;
      width: 12px;
      height: 12px;
    }
  }
`;

const Image = styled(FlexColumn)`
  background: url(${(props) => props.imageSrc});
  background-repeat: none;
  background-size: cover;
  background-position: center center;
  padding-top: 66.49%;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    border-radius: 6px;
  }
`;

const Card = styled(FlexColumn)`
  background-color: ${(props) => extractStyles(props, "blocks")?.background};
  border-radius: 0 32px 0 32px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.5);
  margin-top: -48px;
  padding: 20px 24px 24px;
  width: 290px;
  ${H1} {
    color: ${(props) => extractStyles(props, "blocks")?.title};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => extractStyles(props, "blocks")?.body};
  }
  button {
    margin-top: 24px;
    min-width: initial;

    ${(props) =>
      !!props.theme.branding.landing.colors?.blocks?.button &&
      css`
        background-color: ${extractStyles(props, "blocks")?.button};
        color: ${extractStyles(props, "blocks")?.buttonText ??
        props.theme.branding.colors.actionLabel};
        &:not(:disabled):hover,
        &:not(:disabled):focus {
          background-color: ${extractStyles(props, "blocks")?.button};
          color: ${extractStyles(props, "blocks")?.buttonText ??
          props.theme.branding.colors.actionLabel};
        }
      `};
  }
`;

const ImageLockup = styled(FlexColumn)`
  margin: 16px 0 40px;
  width: 100%;
  & + & {
    ${Card} {
      border-radius: 32px 0 32px 0;
      box-shadow: -4px 8px 16px 0 rgba(0, 0, 0, 0.5);
    }
  }
`;

const Item = styled(FlexColumn)`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  position: relative;
  padding: 16px 12px;
  ${P} {
    font-weight: 400;
    margin-bottom: 4px;
  }
  span {
    font-weight: 600;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const ButtonLocation = styled.button`
  background-color: ${(props) => extractStyles(props, "button")};
  border: ${(props) => extractStyles(props, "buttonBorder")};
  border-radius: 4px;
  color: ${(props) => extractStyles(props, "buttonText")};
  width: 100%;
  height: 40px;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  text-align: center;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${(props) => props.theme.typography.button};
  margin-top: 24px;
  transition: all 100ms ease-out;
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 100ms ease-in;
  }
  ${(props) =>
    props.disabled &&
    css`
      ${(props) => props.theme.button.disabled};
      ${(props) => props.theme.button.primary.disabled};
    `};

  svg {
    margin-right: 8px;
  }
`;

const LoaderInline = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  margin-right: 12px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 2px solid ${(props) => props.theme.palette.gray.lighter};
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default Landing;
