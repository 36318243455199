import styled from "styled-components";
import { ArtistModalProvider } from "./Artist/Components";
import { EventRedirect } from "./EventRedirect";
import { useUser } from "auth";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuthContext } from "Context";
import { Loader } from "notes";
import React from "react";
import { Landing } from "./Landing";

const About = React.lazy(() => import("./About"));
const Terms = React.lazy(() => import("./Terms"));
const Privacy = React.lazy(() => import("./Privacy"));
const Contact = React.lazy(() => import("./Contact"));
const QRPrint = React.lazy(() => import("./QRPrint"));
const QRGroup = React.lazy(() => import("./QRGroup"));
const FanEvent = React.lazy(() => import("./Fan"));
const ArtistEventList = React.lazy(() => import("./Artist"));
const ArtistEvent = React.lazy(() => import("./Artist/Event"));

export const Routes = () => {
  const { claims, user } = useUser();
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext();
  const isAccessAllowed = isAdmin || isArtist;
  if (!user) {
    return (
      <Wrapper>
        <Switch>
          <Route exact path="/" component={Landing} />
        </Switch>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/artists" component={About} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/event/:eventId" component={FanEvent} />
          <Route path="/qr/:eventId" component={QRPrint} />
          <Route path="/qrg/:groupId" component={QRGroup} />
          <Route path="/group/:groupId" component={EventRedirect} />
          {isAccessAllowed && (
            <ArtistModalProvider>
              <Switch>
                <Route exact path="/artist" component={ArtistEventList} />
                <Route path="/artist/:eventId" component={ArtistEvent} />
              </Switch>
            </ArtistModalProvider>
          )}
          <Redirect to="/" />
        </Switch>
      </React.Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.branding.bodyFont};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;
